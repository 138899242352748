<template>
  <div>
    <paystack
      v-if="initiatedTransaction !== {}"
      id="paystack"
      ref="paystack"
      :amount="amount * 100"
      :email="user.email"
      :paystackkey="paystackkey"
      :reference="
        initiatedTransaction.data ? initiatedTransaction.data.reference : ''
      "
      :callback="callback"
      :close="close"
      :embed="false"
    />

    <a-drawer
      title="Create new Complain"
      placement="right"
      :width="width > '640' ? 740 : 320"
      :visible="disputeDrawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="disputeDrawerVisible = false"
    >
      <a-steps size="small" :current="current">
        <a-step v-for="step in steps" :key="step.title">
          <template slot="title"> {{ step.title }} </template>
          <template slot="description">
            {{ step.description }}
          </template>
        </a-step>
      </a-steps>
      <hr class="mt-5 mb-3" />
      <div v-if="current === 0">
        <a-form-model
          ref="complaintForm1"
          :rules="rulesForComplain1"
          layout="vertical"
          :model="complain"
        >
          <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
            <a-form-model-item
              has-feedback
              label="Name of Person/Organization"
              prop="respondentName"
            >
              <a-input
                v-model="complain.respondentName"
                :disabled="creating"
                placeholder="Name of Person/Organization"
              />
            </a-form-model-item>
            <a-form-model-item
              has-feedback
              label="Email of Person/Organization"
              prop="respondentEmail"
            >
              <a-input
                v-model="complain.respondentEmail"
                :disabled="creating"
                placeholder="Email of Person/Organization"
              />
            </a-form-model-item>
          </div>
          <a-form-model-item
            has-feedback
            label="Address of Person/Organization"
            prop="respondentAddress"
          >
            <a-textarea
              v-model="complain.respondentAddress"
              :disabled="creating"
              placeholder="Address of Person/Organization"
            />
          </a-form-model-item>
          <a-form-model-item
            has-feedback
            label="Phone Number of Person/Organization"
            prop="respondentPhone"
          >
            <a-input
              v-model="complain.respondentPhone"
              :disabled="creating"
              placeholder="Phone Number of Person/Organization"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-if="current === 1">
        <a-form-model
          ref="complaintForm2"
          :rules="rulesForComplain2"
          layout="vertical"
          :model="complain"
        >
          <div class="">
            <a-form-model-item
              has-feedback
              label="Type of Complaint"
              prop="type"
            >
              <a-select
                v-model="complain.type"
                :disabled="creating"
                placeholder="Type of Complaint"
              >
                <a-select-option
                  v-for="category in disputeCategory"
                  :key="category._id"
                  :value="category._id"
                  >{{ category.title }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item has-feedback label="Title" prop="title">
              <a-input
                v-model="complain.title"
                :disabled="creating"
                placeholder="Give this dispute a title"
              />
            </a-form-model-item>
            <a-form-model-item
              has-feedback
              label="Describe Complaint in details"
              prop="description"
            >
              <a-textarea
                v-model="complain.description"
                :disabled="creating"
                placeholder="Describe Complaint in details"
              />
            </a-form-model-item>
            <a-form-model-item
              has-feedback
              label="Financial implication"
              prop="costImplication"
            >
              <a-input
                v-model="complain.costImplication"
                type="number"
                :disabled="creating"
                placeholder="Financial implication"
              />
            </a-form-model-item>
            <a-form-model-item
              has-feedback
              label="Attach documents (if any)"
              prop="files"
            >
              <a-upload multiple name="file" @change="handleUpload">
                <a-button> <a-icon type="upload" /> Click to Upload </a-button>
              </a-upload>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <div v-if="current === 2">
        <a-form-model
          ref="complaintForm3"
          :rules="rulesForComplain3"
          layout="vertical"
          :model="complain"
        >
          <div class="grid md:grid-cols-2 grid-cols-1 gap-x-2">
            <a-form-model-item has-feedback label="Your First Name" prop="name">
              <a-input
                v-model="user.firstName"
                disabled
                placeholder="Your First Name"
              />
            </a-form-model-item>
            <a-form-model-item has-feedback label="Your Last Name" prop="email">
              <a-input
                v-model="user.lastName"
                disabled
                placeholder="Your Last Name"
              />
            </a-form-model-item>
            <a-form-model-item has-feedback label="Email" prop="email">
              <a-input v-model="user.email" disabled placeholder="Email" />
            </a-form-model-item>
          </div>
          <a-form-model-item has-feedback label="Your Address" prop="address">
            <a-textarea
              v-model="complain.userAddress"
              :disabled="creating"
              placeholder="Your Address"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div>
        <div class="steps-action">
          <a-button
            v-if="current > 0"
            style="margin-left: 8px"
            @click="current--"
          >
            Previous
          </a-button>
          <a-button
            v-if="current < steps.length - 1"
            type="primary"
            @click="next"
          >
            Next
          </a-button>
          <a-button
            v-if="current == steps.length - 1"
            type="primary"
            :loading="creating"
            @click="createDispute"
          >
            Done
          </a-button>
        </div>
      </div>
    </a-drawer>
    <tab-headers>
      Manage Dispute
      <template #text>
        Create dispute, followup and resolve Dispute here
      </template>
    </tab-headers>
    <div class="flex items-center justify-end">
      <a-button type="primary" @click="disputeDrawerVisible = true"
        >Create a New Dispute</a-button
      >
    </div>
    <div class="bg-white rounded shadow p-2 mt-5">
      <a-tabs v-model="activeKey" default-active-key="1">
        <a-tab-pane key="1" tab="Your Complaints"></a-tab-pane>
        <a-tab-pane key="2" tab="Complaint Against"></a-tab-pane>
      </a-tabs>
    </div>
    <div v-if="activeKey == '1'" class="p-2 mt-3 shadow rounded bg-white">
      <a-table
        :row-key="(record) => record._id"
        :data-source="disputeComplainant"
        :columns="headersForCompliant"
      >
        <span slot="disputeAgainst" slot-scope="record">
          {{ record.respondentName }}
        </span>
        <span slot="initializedOn" slot-scope="record">
          {{ new Date(record.createdAt).toDateString() }}
        </span>
        <span slot="status" slot-scope="record">
          <div
            :class="`
          border py-1 px-3 text-center rounded
            ${
              record.status === 'mediated' || record.status === 'resolved'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`"
          >
            {{ record.status }}
          </div>
        </span>
        <span slot="action" slot-scope="record">
          <a-button
            v-if="record.paymentStatus === 'pending'"
            @click="initiateTransaction(record)"
            >Make Payment
          </a-button>
          <router-link v-else :to="`dispute/${record._id}?state=complainant`"
            >Resolve
          </router-link>
        </span>
      </a-table>
    </div>
    <div v-if="activeKey == '2'" class="p-2 mt-3 shadow rounded bg-white">
      <div class="grid w-full overflow-x-scroll">
        <a-table
          :row-key="(record) => record._id"
          :data-source="disputeResponses"
          :columns="headersForCompliant"
        >
          <span slot="disputeAgainst" slot-scope="record">
            {{ record.respondentName }}
          </span>
          <span slot="initializedOn" slot-scope="record">
            {{ new Date(record.createdAt).toDateString() }}
          </span>
          <span slot="status" slot-scope="record">
            <div
              :class="`
          border py-1 px-3 text-center rounded
            ${
              record.status === 'mediated' || record.status === 'resolved'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`"
            >
              {{ record.status }}
            </div>
          </span>
          <span slot="action" slot-scope="record">
            <a-button
              v-if="record.paymentStatus === 'pending'"
              @click="initiateTransaction(record)"
              >Make Payment
            </a-button>
            <router-link v-else :to="`dispute/${record._id}?state=respondent`"
              >Resolve
            </router-link>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import disputeApi from '../../api/dispute'
import transactionApi from '../../api/transactions'
import siteDataApi from '../../api/site_data'

const headersForCompliant = [
  {
    title: 'Dispute Against',
    key: 'disputeAgainst',
    scopedSlots: { customRender: 'disputeAgainst' },
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Initialized on',
    key: 'initializedOn',
    scopedSlots: { customRender: 'initializedOn' },
  },
  {
    title: 'Status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  components: { TabHeaders },
  layout: 'userdashboard',

  data() {
    return {
      activeKey: '1',
      rulesForComplain1: {
        respondentEmail: [
          {
            pattern:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            required: true,
            message: 'Enter a valid email address',
            trigger: 'blur',
          },
        ],
        respondentName: [
          {
            required: true,
            message: 'Please enter respondent name',
          },
        ],
        respondentAddress: [
          {
            required: true,
            message: 'Please enter respondent address',
          },
        ],
        respondentPhone: [
          {
            required: true,
            message: 'Please enter phone number',
          },
        ],
      },
      rulesForComplain2: {
        type: {
          required: true,
          message: 'Select a type please',
        },
        title: {
          required: true,
          message: 'Give this dispute a title',
        },
        description: {
          required: true,
          message: 'Describe complaint in details',
        },
      },
      rulesForComplain3: {},
      disputeComplainant: [],
      creating: false,
      complain: {},
      disputeDrawerVisible: false,
      current: 0,
      files: [],
      disputeData: {},
      amount: 500,
      headersForCompliant,
      steps: [
        {
          title: 'Person/Organization',
          description:
            'Information about person/organization this complaint is against',
        },
        {
          title: 'Your complaint',
          description:
            '  Describe in details about your complaint against this person/organization',
        },
        {
          title: 'Your Information',
          description: ' Include addition information about you here',
        },
      ],
      initiatedTransaction: {},
      initiatingTransaction: false,
      paystackkey: process.env.VUE_APP_PAYSTACK_KEY,
      disputeResponses: [],
      disputeCategory: [],
    }
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    },
    reference() {
      let text = ''
      const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      return text
    },
  },
  created() {
    this.getDisputeComplainant()
    this.getProcessingFee()
    this.getDisputeAgainst()
    this.getDisputeCategory()
  },
  methods: {
    async getDisputeCategory() {
      const req = await siteDataApi(this.axios).get({ type: 'complaintype' })
      this.disputeCategory = req.data.data
    },
    async getProcessingFee() {
      const req = await siteDataApi(this.axios).get({
        type: 'settings',
      })

      this.amount = req.data.data.find((data) =>
        Object.keys(data).includes('processingFee')
      ).processingFee
    },
    async initiateTransaction(dispute) {
      this.initiatingTransaction = true

      const req = await transactionApi(this.axios).initiate({
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        category: 'dispute',
        categoryId: dispute._id,
        amount: Number(this.amount),
      })
      if (req.error) {
        this.initiatingTransaction = false
        this.$notification.error({
          message: 'Error',
          description: 'Fill the all the required fields',
        })
        return
      }

      this.initiatedTransaction = req.data
      document.getElementById('paystack').click()
    },
    async verifyTransaction(reference) {
      const req = await transactionApi(this.axios).verify({
        reference,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Transaction payment failed',
          description: req.message,
        })
        this.initiatingTransaction = false
        this.selectedTemplateId = false
        return
      }
      this.$notification.info({
        message: 'Payment Successful',
        description: req.message,
      })
      this.$notification.success({
        message: 'Complaint send successfully',
        description: req.message,
      })

      this.initiatingTransaction = false
      this.getDisputeComplainant()
      this.getDisputeAgainst()
    },
    async getDisputeComplainant() {
      const req = await disputeApi(this.axios).getAll({
        params: {
          populate: ['complainant', 'respondent'],
        },
        type: 'my-complaints',
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error getting dispute',
          description: req.message,
        })
        return
      }
      this.disputeComplainant = req.data.data
    },
    async getDisputeAgainst() {
      const req = await disputeApi(this.axios).getAll({
        params: {
          populate: ['complainant', 'respondent'],
        },
        type: 'my-responses',
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error getting dispute',
          description: req.message,
        })
        return
      }
      this.disputeResponses = req.data.data
    },
    async createDispute() {
      this.creating = true
      const req = await disputeApi(this.axios).create({
        ...this.complain,
        files: this.files,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to create dispute',
          description: req.message,
        })
        this.creating = false
        return
      }
      this.$notification.success({
        message: 'Dispute created',
        description: req.message,
      })
      this.creating = false
      this.disputeDrawerVisible = false
      this.disputeData = req.data
      this.initiateTransaction(req.data)
      this.complain = {}
    },
    handleUpload(info) {
      const fileList = [...info.fileList]
      this.files = []
      fileList.forEach((file) => this.files.push(file.originFileObj))
    },
    next() {
      if (this.current === 0) {
        this.$refs.complaintForm1.validate((valid) => {
          if (valid) this.current++
        })
      } else if (this.current === 1) {
        this.$refs.complaintForm2.validate((valid) => {
          if (valid) this.current++
        })
      } else if (this.current === 2) {
        this.$refs.complaintForm3.validate((valid) => {
          if (valid) this.createDispute()
        })
      }
    },
    callback(response) {
      if (
        response.message.toLowerCase() === 'approved' ||
        response.message.toLowerCase() === 'success' ||
        response.state.toLowerCase() === 'success'
      ) {
        this.$notification.info({
          message: 'Processing',
          description: 'Processing payment...',
        })
        this.getDisputeComplainant()
        this.getDisputeAgainst()
        this.verifyTransaction(response.reference)
      } else {
        this.$notification.error({
          message: 'Payment Failed',
          description: 'Unable to make payment, please try again',
        })
      }
    },
    close() {
      this.$notification.error({
        message: 'Cancelled',
        description: 'You cancelled your payment',
      })
      this.initiatingTransaction = false
    },
  },
}
</script>
